






























import Vue from 'vue';

// store
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';

// components
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { SelectOptionItem } from '@/model/types';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';
import { UserResponse } from 'remonade-api/lib';

export default Vue.extend({
  name: 'TaskpoolChannelsContainer',

  components: {
  },

  computed: {
    user(): UserResponse | null {
      return taskpoolViewModule.user;
    },
    tags(): SelectOptionItem[] {
      return tagModule.activatedTags;
    },
    selectedTag(): string {
      return taskpoolViewModule.selectedTag;
    },
  },

  methods: {
    selectTag(id: string) {
      taskpoolViewModule.selectTag(id);
    },
  },

  async mounted() {
    await tagModule.listTags();
  },
});
